import { time } from 'console'
import { set } from 'idb-keyval'
import { defineStore } from 'pinia'

export const useUIStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'ui',
  // a function that returns a fresh state
  state: () => ({
    map: {
      teleportTo: null as string | null,
      attrs: {} as Object | null,
      isReady: false as Boolean,
      mapInstance: null as any,
    },
    lastSearchTimestamp: 0 as number
  }),
  // optional getters
  getters: {
  },
  // optional actions
  actions: {
    setLastSearchTimestamp(timestamp: number) {
      this.lastSearchTimestamp = timestamp
    },
    setMapTeleport(teleportTo: string) {
      this.map.teleportTo = teleportTo
    },
    setMapAttrs(attrs: Object) {
      this.map.attrs = attrs
    },
    setReady(event) {
      this.map.isReady = true
      this.map.mapInstance = event
    },
    resetMap() {
      this.map = {
        teleportTo: 'none' as string | null,
        attrs: {} as Object | null,
        isReady: false
      }
    }
  }
})
